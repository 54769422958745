import React, { useState } from 'react';
import { IWeather, IWeatherV2CardProps } from './types';
import { useApp } from '@wap-client/core';
import Icon from '@/components/base/icon';
import moment from 'moment';
import useMomentLocale from '@/hooks/useMomentLocale';
import getWeatherIcon from './getWeatherIcon';
import Spinner from '@/components/base/spinner';

const WeatherV2Card: React.FC<IWeatherV2CardProps> = ({ cityName }) => {
  const app = useApp();
  useMomentLocale(app.language || 'en');
  // Weather servisinden gelen değerler ile bu state 'de ki degrees 'in valuelerini
  // ,currentDegree ve weatherIconName değerlerini değiştirin.
  const [weather, setWeather] = useState<IWeather>({
    degrees: {
      '00.00': 10,
      '06.00': 12,
      '12.00': 20,
      '18.00': 18,
      '21.00': 15,
    },
    currentDegree: 20,
    otherDegree: {
      rain: '0%',
      humidity: '48%',
      wind: '23 km/s',
    },
    weatherIconName: 'Clear',
    date: moment().format('D MMM'),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const renderTimeForDegrees = () => {
    const { degrees } = weather;
    const sortedDegrees = Object.entries(degrees).sort((a, b) => b[1] - a[1]);
    const maxDegrees = sortedDegrees[0][1];
    const minDegrees = sortedDegrees[sortedDegrees.length - 1][1];

    const convertPercentageRate = (value: number) =>
      ((value - minDegrees) / (maxDegrees - minDegrees)).toFixed(2);

    return Object.entries(degrees).map(([key, value], index) => {
      const percentageRate = convertPercentageRate(value);
      const nextDegreeRate =
        index < Object.entries(degrees).length - 1
          ? convertPercentageRate(Object.entries(degrees)[index + 1][1])
          : convertPercentageRate(Object.entries(degrees)[0][1]);

      return (
        <li
          style={
            {
              '--start': percentageRate,
              '--end': nextDegreeRate,
            } as React.CSSProperties
          }
          key={`weather-chart-${index}`}
        >
          <span className="time">{key}</span>
        </li>
      );
    });
  };

  if (!cityName) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="weather-v2-card-loading">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="weather-v2-card-loading is-error">
        <span className="error-text">
          {app.settings.translations['error-message-weather-first']}{' '}
          <strong>{cityName}</strong>.{' '}
          {app.settings.translations['error-message-weather-second']}
        </span>
      </div>
    );
  }

  return (
    <div className="weather-v2-card">
      <div className="card-head">
        <div className="city-name">
          <Icon name="icon-location" />
          {cityName}
        </div>
        <span className="city-date">/ {weather.date}</span>
      </div>
      <div className="weather-city-info">
        <span className="degree">
          {getWeatherIcon(weather.weatherIconName)}
          {weather.currentDegree}
        </span>
        <div className="other-degree">
          {weather.otherDegree?.rain && (
            <span>
              {app.settings.translations['weather-card-rain']}:{' '}
              {weather.otherDegree.rain}
            </span>
          )}
          {weather.otherDegree?.humidity && (
            <span>
              {app.settings.translations['weather-card-humidity']}:{' '}
              {weather.otherDegree.humidity}
            </span>
          )}
          {weather.otherDegree?.wind && (
            <span>
              {app.settings.translations['weather-card-wind']}:{' '}
              {weather.otherDegree.wind}
            </span>
          )}
        </div>
      </div>
      <ul className="weather-card-chart">{renderTimeForDegrees()}</ul>
    </div>
  );
};

export default WeatherV2Card;
