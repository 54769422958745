import Icon from '@/components/base/icon';
import { WeatherCondition } from './types';

const getWeatherIcon = (weatherKey: WeatherCondition) => {
  switch (weatherKey) {
    case 'Clear':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Partly Cloudy':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Cloudy':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Rain':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Showers':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Thunderstorm':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Snow':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Windy':
      return <Icon name="icon-sun" className="weather-icon" />;
    case 'Overcast':
      return <Icon name="icon-sun" className="weather-icon" />;
    default:
      return null;
  }
};

export default getWeatherIcon;
